import MvpHeader from '../components/MvpHeader/MvpHeader';
import AppHeader from '../components/Header/Header'
import { MenuPoint } from '../types/MenuPoint';
import './Layout.scss';
import Footer from "../components/Footer/Footer";
import { useLocation } from "react-router-dom";

interface LayoutProps {
  children: React.ReactNode;
  menuPoints: MenuPoint[];
  setCurrentTheme: CallableFunction;
  appPrefix: string;
  activePage: number;
  setActivePage: CallableFunction;
  privacyUrl?: string;
  baseColor: string;
  logo: string;
}

export default function Layout({
  children,
  menuPoints,
 
appPrefix,

  privacyUrl,
  baseColor,
  logo, 
  }: LayoutProps) {
  const location = useLocation();
  const className = location.pathname === '/submission' ? 'footer-sticky' : 'footer';

  return (
    <div className="viewport">
      <AppHeader
        menuPoints={menuPoints}
        prefix={appPrefix}
        title="CORA"
        baseColor={baseColor}
        logo={logo}
      
      />
      <div className="bodyWrapper">{children}</div>
      <Footer privacyUrl={privacyUrl} className={className}/>
    </div>
  );
}
