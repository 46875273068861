import {
  Button,
  ButtonSet,
  Column,
  Form,
  Grid,
  TextInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Accordion,
  AccordionItem
} from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { SurveySupplierInput} from '../../../types/SurveySupplierInput';
import '../FormMultisteps.scss';
import './FormStep4_5.scss';
import { useState } from 'react';
import VideoEmbed from '../../Vimeo/Vimeo';
import {validateEmail} from "../../../untils/validateEmail";

const FormStep4_5 = ({ formState, handleChange, prevStep, nextStep }: {formState: SurveySupplierInput, handleChange: any, prevStep: () => void, nextStep: () => void }) => {
  const { t } = useTranslation();
  const [videoKey, setVideoKey] = useState(0); // Add videoKey state
  const [supplierEmailsInvalid, setSupplierEmailsInvalid] = useState<{ row: number, invalid: boolean }[]>(formState.purchasedEmissions.map(() => {return { row: 0, invalid: false }}));
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (formState.purchasedEmissions.some((info) => !info.isConfirmed) || supplierEmailsInvalid.some((invalid) => invalid.invalid)) {
      event.preventDefault(); // Prevent default form submission behavior
    }
    else {
      nextStep();
    }
  }

  const handleSupplierChange = (index: number, event: any) => {
    const { name, value } = event.target;

    // check email only for field supplierEmail
    if (name === 'supplierEmail') {
      supplierEmailsInvalid[index].invalid = !validateEmail(value);
      setSupplierEmailsInvalid([...supplierEmailsInvalid]);
    }

    const updatedInfo = formState.purchasedEmissions.map((info, i) => (
      i === index ? { ...info, [name]: value } : info
    ));
    handleChange({ name: 'purchasedEmissions', value: updatedInfo });
  };

  const sendSurvey = (index: number) => {
    const { supplierEmail, supplierName } = formState.purchasedEmissions[index];
    const emission = formState.purchasedEmissions[index];


    var myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Content-Type', 'application/json');
    var requestOptions = {
      method: 'POST',
      body: JSON.stringify({ supplier_email: supplierEmail, supplier_name: supplierName, cn_code: emission.cn_code }),
      headers: myHeaders,
    };
    fetch(`${backendUrl}/api/surveys/${formState.id}/subsurveys`, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
          // Log the action and update the confirmation status
          console.log(`Survey sent to ${supplierName || 'the supplier'} (${supplierEmail}) for precursor: ${emission.emission_name}`);

          const updatedInfo = formState.purchasedEmissions.map((info, i) => (
              i === index ? { ...info, isConfirmed: true, successMessage: `Survey sent to ${supplierName || 'the supplier'} (${supplierEmail}) for precursor: ${emission.emission_name}` } : info
          ));
          handleChange({ name: 'purchasedEmissions', value: updatedInfo });
        })
        .catch(error => console.log('error', error));
  };

  return (
    <Form onSubmit={handleSubmit} id="subsurvey-step" className="form-step">
      <Grid>
        <Column lg={16} md={8} sm={4}>
          <h4>{t('survey.subsurvey.title')}</h4>
        </Column>

        <Column lg={16} md={8} sm={4}>
          <TableContainer title={t('survey.subsurvey.tableTitle')}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader>{t('survey.step4.dynamicTable.header.emission_name')}</TableHeader>
                  <TableHeader>{t('survey.step4.dynamicTable.header.activity')}</TableHeader>
                  <TableHeader>{t('survey.subsurvey.supplierMail')}</TableHeader>
                  <TableHeader>{t('survey.subsurvey.supplierName')}</TableHeader>
                  <TableHeader>{t('survey.subsurvey.supplierContactName')}</TableHeader>
                  <TableHeader></TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {formState.purchasedEmissions.map((emission, index) => (
                  <TableRow key={index} >
                    <TableCell>{emission.emission_name}</TableCell>
                    <TableCell>{emission.activity}</TableCell>
                    <TableCell>
                      <TextInput
                        id={`supplierEmail_${index}`}
                        name="supplierEmail"
                        labelText=""
                        placeholder={t('survey.subsurvey.supplierEmail.placeholder')}
                        invalid={supplierEmailsInvalid.find((invalid) => invalid.row === index)?.invalid}
                        value={emission.supplierEmail}
                        onChange={(e: any) => handleSupplierChange(index, e)}
                        disabled={emission.isConfirmed}  // Disable after confirmation
                      />
                    </TableCell>
                    <TableCell>
                      <TextInput
                        id={`supplierName_${index}`}
                        name="supplierName"
                        labelText=""
                        placeholder={t('survey.subsurvey.supplierNamePlaceholder')}
                        value={emission.supplierName}
                        required
                        onChange={(e: any) => handleSupplierChange(index, e)}
                        disabled={emission.isConfirmed}  // Disable after confirmation
                      />
                    </TableCell>
                    {/*<TableCell>*/}
                    {/*  <TextInput*/}
                    {/*    id={`supplierContactName_${index}`}*/}
                    {/*    name="supplierContactName"*/}
                    {/*    labelText=""*/}
                    {/*    placeholder={t('survey.subsurvey.supplierContactNamePlaceholder')}*/}
                    {/*    value={emission.supplierContactName}*/}
                    {/*    onChange={(e: any) => handleSupplierChange(index, e)}*/}
                    {/*    disabled={emission.isConfirmed}  // Disable after confirmation*/}
                    {/*  />*/}
                    {/*</TableCell>*/}
                    <TableCell>
                      <Button
                        kind="primary"
                        onClick={() => sendSurvey(index)}
                        disabled={!emission.supplierEmail || !emission.supplierName || emission.isConfirmed || supplierEmailsInvalid[index].invalid}  // Disable if email is not provided or already confirmed
                      >
                        {emission.isConfirmed ? t('survey.subsurvey.sent') : t('survey.subsurvey.sendSurvey')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Column>

        {/* Display success messages below the table */}
        <Column lg={16} md={8} sm={4}>
          {formState.purchasedEmissions.map((info, index) =>
            info.isConfirmed && (
              <div key={index} className="success-message">
                {info.successMessage}
              </div>
            )
          )}
        </Column>
        
        {/* CBAM Academy Video Section */}
        <Column lg={16} md={8} sm={4}>
          <Accordion>
            <AccordionItem title={t('survey.subsurvey.help.title')}>
              <VideoEmbed
                src="https://video.ibm.com/embed/recorded/133967500"
                title="CBAM Academy - Subsurveys"
                width="100%"
                height="auto"
                key={videoKey.toString()} // Convert videoKey to string
              />
            </AccordionItem>
          </Accordion>
        </Column>
      </Grid>
      <Grid>
        <Column lg={16} md={8} sm={4}>
          <div className="step-btn-set-container">
            <ButtonSet className="step-btn-set">
              <Button kind="secondary" tabIndex={0} onClick={prevStep}>
                {t('common.buttons.back')}
              </Button>
              <Button kind="primary" tabIndex={0} type="submit">
                {t('common.buttons.next')}
              </Button>
            </ButtonSet>
          </div>
        </Column>
      </Grid>
    </Form>
  );
};

export default FormStep4_5;
