import React from 'react';
import { useEffect, useState } from 'react';
import {
  Header,
  HeaderContainer,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderMenu,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderName,
  HeaderSideNavItems,
  SideNav,
  SideNavItems,
  SkipToContent,
  HeaderNavigation,

} from '@carbon/react';
import { Link, useSearchParams, useLocation} from 'react-router-dom';
import { MenuPoint } from '../../types/MenuPoint';
import { MvpRoute } from '../../types/MvpRoute';
import LanguageSwitcher from '../MvpHeader/LanguageSwitcher/LanguageSwitcher';
import { Help, Logout } from '@carbon/icons-react';
import { useOidc } from '@axa-fr/react-oidc';
import './Header.scss'


interface MvpHeaderProps {
  menuPoints: MenuPoint[];
  prefix: string;
  title: string;
  baseColor: string;
  logo: string;
}


const AppHeader = ({
  baseColor,
  logo,
  prefix,
 }: MvpHeaderProps) => {
  const { isAuthenticated, logout } = useOidc();
  let [searchParams] = useSearchParams();
  const [surveyId, setSurveyId] = useState<string | null>(null);
  const logoSrc = logo ? `data:image/png;base64,${logo}` : null;
  const location = useLocation(); // Initialize location
  const isActive = (href: string) => location.pathname === href;

  useEffect(() => {
    const urlSurveyId = searchParams.get('surveyId');
    const storedSurveyId = sessionStorage.getItem('surveyId');

    if (urlSurveyId) {
      setSurveyId(urlSurveyId);
      sessionStorage.setItem('surveyId', urlSurveyId); // Store in session
    } else if (storedSurveyId) {
      setSurveyId(storedSurveyId);
    }
  }, [searchParams]);

  // Build URLs with surveyId
  const buildUrlWithSurveyId = (path: string) => {
    return surveyId ? `${path}?surveyId=${surveyId}` : path;}

  return ( <HeaderContainer render={({}: any) => (
              <Header aria-label="IBM Platform Name" style={{ backgroundColor: baseColor }}>
                <SkipToContent />
                <HeaderName prefix={''}>
            {logoSrc && <img src={logoSrc} alt="Logo" className="header-logo" />}
            <p id="supplier-name">{prefix}</p>
          </HeaderName>
                           <HeaderNavigation aria-label="CORA">
            <HeaderMenuItem 
           href={buildUrlWithSurveyId('/')}
           isActive={isActive(buildUrlWithSurveyId('/'))}
            >
              Your CBAM Survey
            </HeaderMenuItem>
            <HeaderMenuItem 
              href={buildUrlWithSurveyId('/academy')}
              isActive={isActive('/academy')}
            >
              CBAM Academy
            </HeaderMenuItem>
          </HeaderNavigation>
                <HeaderGlobalBar>
                <LanguageSwitcher />
                {isAuthenticated && (
                <HeaderGlobalAction
                  aria-label="Logout"
                  onClick={() => logout(`/?surveyId=${surveyId}`)}
                >
                  <Logout size={20} style={{ fill: 'black' }} />
                </HeaderGlobalAction>
              )}
                </HeaderGlobalBar>
              </Header>
      )}
      />
    );
  };

export default AppHeader;
