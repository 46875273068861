import {
  Button,
  ButtonSet,
  Column,
  Accordion,
  AccordionItem,
  Form,
  Grid,
  Tile,
  InlineNotification,
} from '@carbon/react';
import Masonry from 'react-masonry-css';
import {
  Edit
} from '@carbon/react/icons';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../FormMultisteps.scss';
import './FormStep9.scss';
import { MeterChart } from '@carbon/charts-react';
import "@carbon/charts/styles.css";
import { Tooltip } from '@carbon/react';
import { Information } from '@carbon/icons-react';
import VideoEmbed from '../../Vimeo/Vimeo';  // Import the VideoEmbed component

const FormStep9 = ({ formState, prevStep, handleSubmit: submitForm }: any) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [videoKey] = useState(0); // Add videoKey state

  interface IEmission {
    category: string;
    emission_name: string;
    activity: string;
    unit: string;
    emission_id: number;
    direct_multiplier: number;
    indirect_multiplier: number;
  }
  
  interface IFormState {
    directEmissions: IEmission[];
    calculatedEmissionsDirectPerTonne: number;
    route: { name: string; };
    calculationMethod: string;  
  }
  
  interface IFormStep9Props {
    formState: IFormState;
    prevStep: () => void;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    validateFormData(formState)
    submitForm(event);
  };

  useEffect(() => {
    validateFormData(formState);
  }, [formState]);

  const validateFormData = (formState: any) => {
    if (formState.calculationMethod === 'calculated') {
      let totalEmissions = 0;
      let estimatedEmissions = 0;

      formState.directEmissions.forEach((emission: any) => {
        const activityAsNumber = parseFloat(emission.activity);
        if (emission.category === "CBAM_PRRP") {
          estimatedEmissions += (activityAsNumber * emission.direct_multiplier) + (activityAsNumber * emission.indirect_multiplier);
        }
        totalEmissions += (activityAsNumber * emission.direct_multiplier) + (activityAsNumber * emission.indirect_multiplier);
      });

      const estimatedPercentage = (estimatedEmissions / totalEmissions) * 100;

      if (estimatedPercentage > 20) {
        setError('Mehr als 20% der Emissionen sind Schätzungen. Bitte überprüfen Sie die Eingaben.');
      } else {
        setError(null);
      }
    }
  };

  const breakpointColumnsObj = {
    default: 4,
    2000: 3,
    1500: 2,
    900: 1
  };

  const categories: Record<string, { name: string; items: IEmission[] }> = {
    'CBAM_PRFG': { name: 'Fuels & Gases', items: [] },
    'CBAM_PRRP': { name: 'Precursors', items: [] },
    'CBAM_PROI': { name: 'Other Inputs', items: [] }
  };
  
  formState.directEmissions.forEach((emission : IEmission) => {
    if (categories[emission.category]) {
      categories[emission.category].items.push(emission);
    }
  });
  
  const sourceStreamContent = Object.entries(categories).map(([key, category]) => {
    if (category.items.length > 0) {
      return (
        <Accordion id={'tile-accordion'} key={key}>
          <AccordionItem id={`accordion-${key}`} title={`${category.name} (${category.items.length})`}>
            {category.items.map(item => (
              <p style={{color: 'grey'}} key={item.emission_id}>
                {`${item.emission_name} (${item.activity}${item.unit})`}
              </p>
            ))}
          </AccordionItem>
        </Accordion>
      );
    }
    return null;
  }).filter(Boolean);

  const tileData = [
    {
      chart: (
        <MeterChart
          data={[
            { group: t('survey.step9.directemissions.title'), value: formState.calculatedEmissionsDirectPerTonne },
            { group: t('survey.step9.indirectemissions.title'), value: formState.calculatedEmissionsIndirectPerTonne },
          ]}
          options={{
            title: 'Total Emissions',
            height: '130px',
            meter: {
              proportional: {
                total: formState.calculatedEmissionsTotalPerTonne,
                unit: 't CO2e / tp'
              }
            },
            color: {
              pairing: {
                option: 2
              }
            }
          }}
        />
      ),
    },
    {
      title: "Installation Data",
      items: [
        { name: "Installation name", value: formState.installationName },
        { name: "Economic activity", value: formState.economicActivity },
        {
          name: "Address",
          value: (
            <>
              {`${formState.street} ${formState.streetNumber}`}<br />
              {`${formState.POBox}`} {`${formState.additionalLine}`}<br />
              {`${formState.postCode}`} {`${formState.city}`}<br />
              {`${formState.country.country_name}`}
            </>
          ),
        },
        {
          name: "Contact details",
          value: (
            <>
              {`${formState.representative}`}<br />
              {`${formState.telephone}`}<br />
              {`${formState.email}`}
            </>
          ),
        }
      ]
    },
    {
      title: "Direct Emissions",
      items: [
        { name: "Calculated emissions", value: `${formState.calculatedEmissionsDirectPerTonne} t CO2e / tp` },
        { name: "Production route", value: formState.route.name },
        { name: "Source streams", value: <>{sourceStreamContent}</> }
      ]
    },
    {
      title: "Indirect Emissions",
      items: [
        { name: "Calculated emissions", value: `${formState.calculatedEmissionsIndirectPerTonne} t CO2e / tp` },
        ...(formState.calculationMethod === 'calculated' ? [
          { name: "Energy usage", value: `${formState.indirectEmission} Mwh` }
        ] : []),
      ]
    },
    ...(formState.calculationMethod === 'calculated' ? [
      {
        title: "Activity Level",
        items: [
          { name: "Total production", value: `${formState.productionTotal} t` },
          { name: "Scrap", value: `${formState.productionScrap} t` },
          { name: "Production - Scrap", value: `${formState.productionCalc} t` },
        ]
      }
    ] : []),
    ...(formState.shareCoveredPrice ? [
      {
        title: "Carbon price",
        items: [
          { name: "Carbon price", value: `${formState.shareCoveredPrice} %` },
          { name: "Free allocation", value: `${formState.shareRebate} %` },
        ]
      }
    ] : []),
    {
      title: "Reporting Period",
      items: [
        { name: "Start Period", value: `${formState.start}` },
        { name: "End Period", value: `${formState.end}` }
      ]
    },
    {
      title: "Good information",
      items: [
        { name: "CN code", value: formState.CNNumber },
        {
          name: "CN name",
          value: (
            <Tooltip align="top" label={formState.CNName}>
              <div className="cn-name-tooltip">
                {formState.CNName.length > 15 ? `${formState.CNName.substring(0, 15)}...` : formState.CNName}
              </div>
            </Tooltip>
          ),
        },
        { name: "AGC", value: formState.AGC },
        { name: "Calculation method", value: formState.calculationMethod }
      ]
    },
    ...(formState.specificParameters && formState.specificParameters.length > 0 ? [
      {
        title: "Additional Reporting Parameters",
        items: formState.specificParameters.map((param: { mandatory: boolean, definition: string, name: string, value: any, unit: string; }) => ({
          name: (
            <>
              {param.name}{' '}
              <Tooltip align="bottom" label={param.definition}>
                <Information size={'12'} color='grey' />
              </Tooltip>
            </>
          ),
          value: `${param.value} ${param.unit !== 'text' ? param.unit : ''}`.trim()
        }))
      }
    ] : []),
  ];

  return (
    <>
      <Form onSubmit={handleFormSubmit} id="step9" className="form-step">
        <h3 className='form-step-title'>{t('survey.step9.name')}</h3>
        <InlineNotification
          kind="info"
          hideCloseButton
          title={t('survey.step9.infoTitle')}
          subtitle={t('survey.step9.infoSubtitle')}
        />
        <div style={{ marginTop: '16px' }}>
          {error && (
            <InlineNotification
              kind="warning"
              title={t('survey.step9.warningTitle')}
              subtitle={t('survey.step9.warningSubtitle')}
              onClose={() => setError(null)}
              lowContrast={false}
            />
          )}
        </div>

        {/* Masonry tiles */}
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {tileData.map((tile, index) => (
            <Column lg={5} md={4} sm={4} key={index}>
              <Tile className="tile-container">
                <div className="expandable-tile-container">
                  <div className="tile-header">
                    <span className="tile-title">{tile.title}</span>
                    {!tile.chart && <Button hasIconOnly renderIcon={Edit} iconDescription="Direct edit currently unavailable" onClick={() => {/* handle edit */ }} size="md" kind="ghost" />}
                  </div>
                  {tile.items && tile.items.map((item: any, idx: any) => (
                    <div className="tile-item" key={idx}>
                      <span className="tile-item-name">{item.name}</span>
                      <span className="tile-item-value">{item.value}</span>
                    </div>
                  ))}
                  {tile.chart && tile.chart}
                </div>
              </Tile>
            </Column>
          ))}
        </Masonry>

        {/* CBAM Academy Section */}
        <Column lg={16} md={8} sm={4}>
          <Accordion>
            <AccordionItem title={t('survey.subsurvey.help.title')}>
              <VideoEmbed
                src="https://video.ibm.com/embed/recorded/133967503"
                title="CBAM Academy - Survey Finished - Next Steps"
                width="100%"
                height="auto"
                key={videoKey.toString()}
              />
            </AccordionItem>
          </Accordion>
        </Column>

        {/* Form buttons */}
        <Grid>
          <Column lg={16} md={8} sm={4}>
            <div className="step-btn-set-container">
              <ButtonSet className="step-btn-set">
                <Button kind="secondary" tabIndex={0} onClick={prevStep}>
                  {t('common.buttons.back')}
                </Button>
                <Button kind="primary" tabIndex={0} type="submit">
                  {t('common.buttons.submit')}
                </Button>
              </ButtonSet>
            </div>
          </Column>
        </Grid>
      </Form>
    </>
  );
};

export default FormStep9;
