const faqData = [
    {
        id: 1,
        imgPath: "/assets/img/1.png",
        question: "Why is the EU putting in place a Carbon Border Adjustment Mechanism?",
        answer: "The EU leads global climate change efforts, targeting a 55% reduction in emissions by 2030 and aiming for climate neutrality by 2050 through the European Green Deal. The Fit for 55 proposals in July 2021 advanced these goals, with key policies now enacted into EU law, including the Carbon Border Adjustment Mechanism (CBAM).",
        category: "Regulation",
      },
      {
        id: 2,
        imgPath: "/assets/img/2.png",
        question: "How does the CBAM work?",
        answer: "The Carbon Border Adjustment Mechanism (CBAM) aligns with EU international commitments and WTO rules, mirroring the EU Emissions Trading System (ETS). It charges importers for the carbon emissions embedded in goods according to EU ETS standards, starting in 2026. Importers must purchase certificates reflecting the EU carbon price. Non-EU producers can offset their CBAM costs if they've paid for carbon emissions in their own countries. CBAM aims to prevent carbon leakage and motivate non-EU producers and countries to adopt greener practices and carbon pricing.",
        category: "Regulation",
      },
      {
        id: 3,
        imgPath: "/assets/img/3.png",
        question: "How does CBAM interact with the EU Emissions Trading System (ETS)?",
        answer: "The EU Emissions Trading System (ETS) is the world's first large-scale carbon market, aiming to reduce greenhouse gas emissions from power production and heavy industry by setting a cap and requiring emissions allowances. To mitigate carbon leakage, industries receive some free allowances, which will decrease over time to enhance the incentive for decarbonization. The Carbon Border Adjustment Mechanism (CBAM) is being introduced as these free allowances are phased out, especially from 2026 onwards, to strengthen the EU's climate objectives. CBAM, which differs slightly from the ETS, requires importers to buy certificates for their goods' embedded emissions without a cap on the number of certificates. The price of CBAM certificates will reflect the ETS market price. Full CBAM implementation in 2026 will adjust in line with the ETS, particularly concerning the reduction of free allowances. Until 2034, when free allowances are fully eliminated, CBAM will only target emissions not already covered by ETS allowances, ensuring fair treatment for EU producers and importers alike.",
        category: "Regulation",
      },
      {
        id: 4,
        imgPath: "/assets/img/4.png",
        question: "How is the CBAM compatible with other ETS systems outside the EU?",
        answer: "The CBAM ensures fair treatment for imported goods compared to EU products through three key features: 1) It considers the actual embedded emissions, rewarding exporters to the EU for reducing their carbon footprint with lower CBAM payments; 2) The price for CBAM certificates is the same as the carbon price under the EU ETS for EU producers; and 3) Any carbon price paid outside the EU for the embedded emissions is deducted to prevent double pricing. Additionally, the Commission will establish secondary legislation to acknowledge effective carbon prices paid in third countries. During the transitional period, importers must report the carbon price from the country of origin, accounting for any reductions or compensations..",
        category: "Regulation",
      },
      {
        id: 5,
        imgPath: "/assets/img/5.png",
        question: "Which sectors does the new mechanism cover and why were they chosen?",
        answer: "The CBAM initially applies to imports of goods in the following sectors: • Cement • Iron and Steel • Aluminium • Fertilisers • Hydrogen • Electricity. These sectors were selected following specific criteria, in particular their high risk of carbon leakage and high emission intensity which will eventually – once fully phased in –represent more than 50% of the emissions of the industry sectors covered by the ETS. In the future, the CBAM may be extended to other ETS sectors.",
        category: "Regulation",
      },
      {
        id: 5,
        imgPath: "/assets/img/5.png",
        question: "I am unable to separate the product inputs for my installation based on a tariff code / cn number. What should I do?",
        answer: "There are two valid strategies to create a valid emissions report in this scenario: 1. Use total production values. This means that all source streams (materials used in production) are entered and the full production output is also listed. It is important that your inputs follow the same logic, e.g. not using the total production output would result in very high emissions in this scenario. 2. If your input materials are very similar and there are no major differences in energy consumption during the different production processes, you can break down the numbers based on other data, such as production output based on groups of goods produced. For example, let's say that the approximate percentage of the total production of the goods in question is 30%. In this scenario, you can use 30% of the materials used and 30% of the energy consumption..",
        category: "CBAM",
      },
      {
        id: 6,
        imgPath: "/assets/img/5.png",
        question: "Can I use the data generated for other clients who request CBAM data?",
        answer: "Yes, you can use the data generated for other customers. Please make sure that the CN number is the same.",
        category: "CBAM",
      },
      {
        id: 7,
        imgPath: "/assets/img/5.png",
        question: "How often do I have to update the CBAM survey?",
        answer: "CBAM surveys need to be updated quarterly, but since your data is secured & stored, we'll only ask for minor updates & additional information in Q4. The base data for all 2024 surveys will be from 2023, the base data for all 2025 surveys will be 2024. In theory, One report per production year is sufficient.",
        category: "CBAM",
      },
      {
        id: 8,
        imgPath: "/assets/img/5.png",
        question: "I use renewable energy in my production process. Where can I add this information and upload the certificates?",
        answer: "We've decided to make the first CBAM survey as easy as possible for our suppliers. Therefore, we have prioritized compliance over accuracy for this CBAM reporting quarter. As we all become more familiar with the regulation, additional information can be submitted to increase the accuracy of your report. Our collective goal is to have highly accurate data in Q3 or Q4 2025.",
        category: "CBAM",
      },
      {
        id: 9,
        imgPath: "/assets/img/5.png",
        question: "I don't want to share information about my supply chain with my European customer.",
        answer: "The CBAM regulation, among several other active or soon to be relevant regulations, requires a certain level of transparency of your supply chain. Hitachi Energy would not be able to produce a compliant CBAM report for the EU Commission without the necessary sub-survey data.",
        category: "CBAM",
      },
];

export default faqData;