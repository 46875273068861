import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

var english = require('./locales/en.json');
var german = require('./locales/de.json');
var chinese = require('./locales/cn.json');
var spanish = require('./locales/es.json');
var french = require('./locales/fr.json');
var turkish = require('./locales/tr.json')
var hindi = require('./locales/in.json')

const resources = {
  en: english,
  de: german,
  cn: chinese,
  es: spanish,
  fr: french,
  tr: turkish,
  in: hindi
};

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: 'en',
  lng: 'en',
  resources,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
