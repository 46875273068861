import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  Button,
  ButtonSet,
  Column,
  DatePicker,
  DatePickerInput,
  Form,
  Grid,
} from '@carbon/react';
import { useTranslation } from 'react-i18next';
import '../FormMultisteps.scss';
import './FormStep2.scss';
import { SurveySupplierInput } from '../../../types/SurveySupplierInput';
import VideoEmbed from '../../Vimeo/Vimeo'; // Import VideoEmbed component

const EN_GB_LOCALES = 'en-GB';


interface FormStep2State {
  start: Date;
  end: Date;
  faq: string;
}

type Props = {
  formState: SurveySupplierInput;
  handleChange: (event: { target: { name: any; value: any; }; name?: any; value?: any; }) => void;
  nextStep: () => void;
  prevStep: () => void;
};

const parseLocaleDateString = (date: string) => {
  const [day, month, year] = date.split('/');
  return new Date(`${year}-${month}-${day}`);
}

const FormStep2 = ({ formState, handleChange, nextStep, prevStep }: Props) => {
  const { t } = useTranslation();
  const [start, setStart] = useState<Date | null>(parseLocaleDateString(formState.start) || null);
  const [end, setEnd] = useState<Date | null>(parseLocaleDateString(formState.end) || null);
  const [videoKey, setVideoKey] = useState(0); // Add videoKey state

  useEffect(() => {
    setStart(parseLocaleDateString(formState.start));
    setEnd(parseLocaleDateString(formState.end));
  }, [formState.start, formState.end]);

  const handleStartDateChange = (dates: Date[]) => {
    const [newStart] = dates;
    const newEnd = new Date(newStart);
    newEnd.setFullYear(newStart.getFullYear() + 1);
    newEnd.setDate(newEnd.getDate() - 1);

    setStart(newStart);
    setEnd(newEnd);

    handleChange({ target: { name: 'start', value: newStart.toLocaleDateString(EN_GB_LOCALES) } });
    handleChange({ target: { name: 'end', value: newEnd.toLocaleDateString(EN_GB_LOCALES) } });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!start || !end) {
      alert('Both start and end dates must be selected.');
      return;
    }

    nextStep();
  };

  return (
    <Form onSubmit={handleSubmit} id="step2" className="form-step">
      <div className="grid-container-element">
        <div className="grid-child-element">
          <Grid>
            <Column lg={16} md={8} sm={4}>
              <h4>{t('survey.step2.name')}</h4>
              {t('survey.step2.description')}
            </Column>
            <Column lg={16} md={6} sm={4}>
              <DatePicker
                minDate="1/1/2023"
                datePickerType="single"
                dateFormat="d/m/Y"
                value={start ? [start] : []}
                onChange={handleStartDateChange}
              >
                <DatePickerInput
                  name="start"
                  id="start"
                  labelText={t('survey.step2.start.labelText')}
                  helperText={t('survey.step2.start.helperText')}
                  invalidText={t('survey.step2.start.invalidText')}
                  placeholder={t('survey.step2.start.placeholder')}
                  size="md"
                  disabled={false}
                />
                <DatePickerInput
                  name="end"
                  id="end"
                  labelText={t('survey.step2.end.labelText')}
                  helperText={t('survey.step2.end.helperText')}
                  invalidText={t('survey.step2.end.invalidText')}
                  placeholder={t('survey.step2.end.placeholder')}
                  size="md"
                  value={end ? end.toLocaleDateString(EN_GB_LOCALES) : ''}
                  readOnly
                />
              </DatePicker>
            </Column>
          </Grid>
        </div>
        <div className="grid-child-element">
          <Grid>
            <Column lg={16} md={8} sm={4}>
              <h5 className="faq">{t('survey.step2.faq.title')}</h5>
              <Accordion>
                <AccordionItem title={t('survey.step2.faq.accordion1.title')}>
                  {t('survey.step2.faq.accordion1.text')}
                  <div>
                  <VideoEmbed
                  src="https://video.ibm.com/embed/recorded/133972395"
                  title="CBAM Academy - Reporting Periods for Operators"
                  width="100%"
                  height="auto"
                  key={videoKey.toString()} // Convert videoKey to string
                />
                  </div>
                </AccordionItem>
                <AccordionItem title={t('survey.step2.faq.accordion2.title')}>
                  {t('survey.step2.faq.accordion2.text')}
                </AccordionItem>
                <AccordionItem title={t('survey.step2.faq.accordion3.title')}>
                  {t('survey.step2.faq.accordion3.text')}
                </AccordionItem>
              </Accordion>
            </Column>
          </Grid>
        </div>
      </div>
      <Grid>
        <Column lg={16} md={8} sm={4}>
          <div className="step-btn-set-container">
            <ButtonSet className="step-btn-set">
              <Button kind="secondary" tabIndex={0} onClick={prevStep}>
                {t('common.buttons.back')}
              </Button>
              <Button kind="primary" tabIndex={0} type="submit">
                {t('common.buttons.next')}
              </Button>
            </ButtonSet>
          </div>
        </Column>
      </Grid>
    </Form>
  );
};

export default FormStep2;
