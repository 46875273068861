import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Tabs,
    Tab,
    TabList,
    TabPanels,
    TabPanel,
    Grid,
    Column,
    Modal,
    Search,
   } from '@carbon/react';
  import ReactDOM from 'react-dom';
  import { useTranslation } from 'react-i18next';
  import '../CbamAcademy/CbamAcademy.scss';
  import React, { useState, useRef, useEffect } from 'react';
  
  const PrivacyNotice = () => {
    const { t } = useTranslation();
 

   
    return (
  
      <Grid className="landing-page" fullWidth>
      <Column lg={16} md={8} sm={4} className="landing-page__r2">

    <div>
      <h1>Privacy Notice</h1>
      <p>
        At <strong>Ventum Consulting GmbH & Co. KG</strong> ("Ventum Consulting"), we are committed to protecting your privacy and ensuring that your personal data is handled securely and transparently. This Privacy Notice explains how we collect, use, and safeguard your personal information when you use our CORA web app.
      </p>

      <h2>1. Data We Collect:</h2>
      <p>We may collect the following categories of data:</p>
      <ul>
        <li><strong>User Data:</strong> Information provided by you, including name, email address, and other personal identification data.</li>
        <li><strong>Data for Compliance with the EU Carbon Border Adjustment Mechanism (CBAM):</strong> All data required to meet the obligations under the EU CBAM regulation, which may include carbon emissions data and other environmental metrics.</li>
        <li><strong>Usage Data:</strong> Information about your interactions with our web app, including the usage of the IBM Video Streaming service.</li>
        <li><strong>Identity Data:</strong> If you are a customer using Keycloak for authentication, we collect identity information provided by your identity provider.</li>
      </ul>

      <h2>2. Purpose of Data Collection:</h2>
      <p>We use the data we collect to:</p>
      <ul>
        <li>Operate, maintain, and improve the CORA web app</li>
        <li>Fulfill regulatory requirements under the EU CBAM</li>
        <li>Monitor and analyze the use of the IBM Video Streaming service for user experience optimization</li>
        <li>Authenticate and secure user accounts via Keycloak and other identity providers</li>
      </ul>

      <h2>3. Data Sharing:</h2>
      <p>We do not share your data with third parties except under the following circumstances:</p>
      <ul>
        <li><strong>Service Providers:</strong> We may share data with trusted service providers who assist in the operation and management of the CORA web app, including providers of video streaming services.</li>
        <li><strong>Legal Obligations:</strong> We may disclose your data where required to do so by law or to comply with legal processes, regulatory requests, or court orders.</li>
      </ul>

      <h2>4. Data Security:</h2>
      <p>We implement industry-standard security measures, including encryption, to protect your personal data and ensure the secure operation of our systems. Your data is stored securely and only accessible by authorized personnel.</p>

      <h2>5. Your Rights:</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access the personal data we hold about you</li>
        <li>Request corrections or updates to your data</li>
        <li>Request the deletion of your data, subject to legal and regulatory obligations</li>
        <li>Withdraw consent for data processing, where applicable</li>
      </ul>
      <p>To exercise any of these rights, please contact us at <a href="mailto:cbam@ventum.de">cbam@ventum.de</a>.</p>

      <h2>6. Cookies and Tracking:</h2>
      <p>We use cookies and similar technologies to monitor your activity on the CORA web app, improve user experience, and gather analytics regarding the usage of IBM Video Streaming.</p>

      <h2>7. Changes to This Privacy Notice:</h2>
      <p>This Privacy Notice may be updated periodically to reflect changes in our practices or relevant regulations. We will notify you of any significant changes by posting the updated notice on our web app.</p>

      <h2>8. Contact Us:</h2>
      <p>If you have any questions about this Privacy Notice or how your data is handled, please contact us at:</p>
      <p>
        Ventum Consulting GmbH & Co. KG<br />
        Georg-Brauchle-Ring 23-25<br />
        80992 Munich<br />
        Email: <a href="mailto:cbam@ventum.de">cbam@ventum.de</a><br />
        Phone: +49 89 1222 1964 2
      </p>
    </div>
  </Column>
 
    </Grid>
    );
  };
  
  export default PrivacyNotice;
  